export default function getUserAbilities(abilitieArr) {
  console.log('***** getUserAbilities *****')
  // let abilities = []

  // switch (abilitieArr) {
  //   case 'Management':
  //     abilities = JSON.parse('[{"action": "manage","subject": "all"}]')
  //     break

  //   default:
  //     abilities = JSON.parse('[{"action": "manage","subject": "all"}]')
  //     break
  // }
  const abilities = []

  abilitieArr.forEach(element => {
    element.actions.forEach(action => {
      const item = {}
      item.subject = element.subject
      item.action = action.action
      abilities.push(item)
    })
  })
  // temp add manage all
  // const tmp = {}
  // tmp.subject = 'all'
  // tmp.action = 'manage'
  // abilities.push(tmp)
  // Authorized User
  const tmp1 = {}
  tmp1.subject = 'Auth'
  tmp1.action = 'read'
  abilities.push(tmp1)

  return {
    abilities,
  }
}
